export const menuList = [
  {
    id: 1,
    name: "PDF转Word",
    pic: require("@/assets/img/trans/t/pdf-word.png"),
    list_icon: require("@/assets/img/home/list/i1.png"),
    c_icon: require("@/assets/img/home/upload/i-pdf.png"),
    href: "/pdf-to-word",
    type: 4,
    download: 1,
    last: "doc",
    isHot: false
  },
  {
    id: 2,
    name: "PDF转Excel",
    href: "/pdf-to-excel",
    pic: require("@/assets/img/trans/t/pdf-excel.png"),
    list_icon: require("@/assets/img/home/list/i2.png"),
    c_icon: require("@/assets/img/home/upload/i-pdf.png"),
    type: 1,
    download: 1,
    last: "xlsx",
    isHot: false
  },
  {
    id: 3,
    name: "PDF转PPT",
    href: "/pdf-to-ppt",
    pic: require("@/assets/img/trans/t/pdf-ppt.png"),
    list_icon: require("@/assets/img/home/list/i3.png"),
    c_icon: require("@/assets/img/home/upload/i-pdf.png"),
    type: 6,
    download: 1,
    last: "ppt",
    isHot: false
  },
  {
    id: 4,
    name: "PDF转JPG",
    href: "/pdf-to-img",
    pic: require("@/assets/img/trans/t/pdf-img.png"),
    list_icon: require("@/assets/img/home/list/i4.png"),
    c_icon: require("@/assets/img/home/upload/i-pdf.png"),
    type: 7,
    download: 2,
    last: "zip",
    isHot: false
  },
  {
    id: 5,
    name: "Word转PDF",
    href: "/word-to-pdf",
    pic: require("@/assets/img/trans/t/word-pdf.png"),
    list_icon: require("@/assets/img/home/list/i5.png"),
    c_icon: require("@/assets/img/home/upload/i-word.png"),
    type: 8,
    download: 1,
    last: "pdf",
    isHot: false
  },
  {
    id: 6,
    name: "Excel转PDF",
    href: "/excel-to-pdf",
    pic: require("@/assets/img/trans/t/excel-pdf.png"),
    list_icon: require("@/assets/img/home/list/i6.png"),
    c_icon: require("@/assets/img/home/upload/i-excel.png"),
    type: 9,
    download: 1,
    last: "pdf",
    isHot: false
  },
  {
    id: 7,
    name: "PPT转PDF",
    href: "/ppt-to-pdf",
    pic: require("@/assets/img/trans/t/ppt-pdf.png"),
    list_icon: require("@/assets/img/home/list/i7.png"),
    c_icon: require("@/assets/img/home/upload/i-ppt.png"),
    type: 10,
    download: 1,
    last: "pdf",
    isHot: false
  },
  {
    id: 8,
    name: "图片转PDF",
    href: "/img-to-pdf",
    pic: require("@/assets/img/trans/t/img-pdf.png"),
    list_icon: require("@/assets/img/home/list/i8.png"),
    c_icon: require("@/assets/img/home/upload/i-img.png"),
    type: 11,
    download: 1,
    last: "pdf",
    isHot: false
  },
  {
    id: 9,
    name: "CAD转PDF",
    href: "/cad-to-pdf",
    pic: require("@/assets/img/trans/t/cad-pdf.png"),
    list_icon: require("@/assets/img/home/list/i9.png"),
    c_icon: require("@/assets/img/home/upload/i-cad.png"),
    type: 13,
    download: 1,
    last: "pdf",
    isHot: false
  },
  {
    id: 10,
    href: "/ofd-to-pdf",
    name: "OFD转PDF",
    pic: require("@/assets/img/trans/t/ofd-pdf.png"),
    list_icon: require("@/assets/img/home/list/i10.png"),
    c_icon: require("@/assets/img/home/upload/i-ofd.png"),
    type: 12,
    download: 1,
    last: "pdf",
    isHot: false
  }
];

export const feelTools = [
  {
    id: 1,
    name: "蓝山压缩",
    url: "https://www.lanshan.com/yasuo/"
  },
  {
    id: 2,
    name: "蓝山看图王",
    url: "https://www.lanshan.com/pic/"
  },
  {
    id: 3,
    name: "蓝山Office",
    url: "https://www.lanshan.com/office/"
  }
];

export const handleList = [
  {
    id: 1,
    pic: require("@/assets/img/home/list/s1.png"),
    name: "转换效果",
    desc1: "将您的文件上传至在线转换器中，我们将它",
    desc2: "转化成您所需要的格式"
  },
  {
    id: 2,
    pic: require("@/assets/img/home/list/s2.png"),
    name: "转换速度",
    desc1: "上传文件后，仅需几秒，转换完成",
    desc2: ""
  },
  {
    id: 3,
    pic: require("@/assets/img/home/list/s3.png"),
    name: "云端转换",
    desc1: "多个服务器，免费批量转换稳定输出",
    desc2: ""
  },
  {
    id: 4,
    pic: require("@/assets/img/home/list/s4.png"),
    name: "不用担心安全问题",
    desc1: "确保文档安全是我们的首要任务，所有文件",
    desc2: "均使用加密技术"
  },
  {
    id: 5,
    pic: require("@/assets/img/home/list/s5.png"),
    name: "随时随地使用",
    desc1: "只要有网，您就可以在任何地方使用",
    desc2: ""
  },
  {
    id: 6,
    pic: require("@/assets/img/home/list/s6.png"),
    name: "",
    desc1: "扫码添加客服，工作日09:00~18:00",
    desc2: ""
  }
]
