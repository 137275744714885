/**
 * 收集Error信息
 */
// import { Message } from 'element-ui'
function onError(error) {
  console.log('error', error)
  // let message = '请求出错，请重试'
  const originalRequest = error.config;

  if (error.code === "ECONNABORTED" && error.message.indexOf("timeout") !== -1 && !originalRequest._retry) {
    // message = '网络开小差了~'
  } else if (error.data.code !== 0) {
    // if (error.data.code === 101) {
    //   message = '请先登录~';
    // } else {
    //   message = error.data.message
    // }
  } else if (error.response) {
    console.log(`error.response.data: ${error.response.data}`);
    console.log(`error.response.status: ${error.response.status}`);
    console.log(`error.response.headers: ${error.response.headers}`);
  } else if (error.request) {
    console.log(`error.request: ${error.request}`);
  } else {
    console.log(`error.message: ${error.message}`);
  }
  // Message({
  //   message: message,
  //   type: "error",
  //   duration: 5 * 1000
  // });
}

export default onError;
