<template>
  <div class="head-fixed">
    <div class="header">
      <div class="header-content">
        <!-- {{ nickname }}
        <img style="width: 40px; height: 40px" :src="nickhead" alt="" />-->
        <!-- logo -->
        <div class="header-left">
          <div class="header-icon" @click="toHome">
            <img src="../assets/img/homeLogo.png" alt />
            <!-- <span>PDF在线转换</span> -->
          </div>
          <!-- 首页 -->
          <div class="header-home" @click="toHome">首页</div>
          <!-- PDF转换 -->
          <div class="header-ml">
            <el-dropdown>
              <span class="el-dropdown-link">
                实用工具
                <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <a
                  v-for="navItem in feelTools"
                  :key="navItem.id"
                  :href="navItem.url"
                  target="_blank"
                >
                  <el-dropdown-item>{{ navItem.name }}</el-dropdown-item>
                </a>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <a
            class="header-home"
            style="margin-left: 35px"
            href="http://file.zsincer.com/pack/pdf/jk/jkpdf_guanf.exe"
          >
            下载客户端
          </a>
        </div>
        <!-- 操作按钮 -->
        <div class="header-btn">
          <!-- <div class="header-history" @click="toHistory" v-if="isLogin" v-cloak>转换记录</div> -->
          <!-- 开通会员 -->
          <div class="header-vip" @click="openVip">
            <img class="header-vip-icon" src="../assets/img/vip/vip-icon.png" />
            <div class="header-vip-text" v-if="isVip" v-cloak>续费会员</div>
            <div class="header-vip-text" v-else v-cloak>开通会员</div>
          </div>
          <!-- 登录 -->
          <div
            v-if="!isLogin"
            class="header-register"
            @click="OpenWxWebLogin"
            v-cloak
          >
            登录
          </div>
          <div
            class="header-head-wrap"
            v-else
            @mouseenter="enter"
            @mouseleave="enter"
            v-cloak
          >
            <!-- 用户信息 -->
            <div class="header-userinfo-wrap">
              <div v-show="showUser" class="header-user-info">
                <div class="header-user-top">
                  <div class="header-top-header">
                    <img src="../assets/img/default-head.png" alt />
                  </div>
                  <div class="header-top-name">{{ userName }}</div>
                </div>
                <div class="header-user-b">
                  <div class="vip-times">会员信息：{{ timeZone }}</div>
                  <div class="vip-btn" @click="openVip" v-if="isVip" v-cloak>
                    立即续费VIP
                  </div>
                  <div class="vip-btn" @click="openVip" v-else v-cloak>
                    立即开通VIP
                  </div>
                  <div class="vip-item-wrap">
                    <div
                      class="vip-item"
                      v-for="(item, index) in vipList"
                      :key="index"
                    >
                      <div class="vip-item-img">
                        <img :src="item.pic" alt />
                      </div>
                      <div class="vip-item-name">{{ item.name }}</div>
                    </div>
                  </div>
                  <div class="vip-login-out">
                    <div class="login-out" @click="loginOut">退出登录</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="header-head">
              <div class="vip-border" v-if="isVip" v-cloak>
                <img src="../../src/assets/img/vip/vip-b.png" alt />
              </div>
              <!-- <img class="user-header" :src="head" alt /> -->
              <img
                class="user-header"
                src="../assets/img/default-head.png"
                alt
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 登录dialog -->
      <el-dialog
        append-to-body
        :visible.sync="loginDialog"
        width="520px"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <!-- 头部关闭icon -->
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 20px 0px 20px;
          "
        >
          <div style="width: 34px; height: 34px" />
          <div
            style="width: 34px; height: 34px; cursor: pointer"
            @click="handleClose"
          >
            <img src="../assets/img/home/close.png" alt />
          </div>
        </div>
        <!-- 登录tabs -->
        <div class="line-login" />
        <el-tabs v-model="activeName">
          <!-- 微信登录 -->
          <el-tab-pane label="使用微信登录" name="first">
            <div id="login_container" class="wx-code">
              <div id="wxloginD" class="wx-login-mask" v-if="wxLoading">
                <div class="wx-login-loading-wrap">
                  <i class="el-icon-loading wx-login-loading"></i>
                </div>
              </div>
              <img :src="wxLoginImg" alt="" />
            </div>
            <div class="wx-login-desc">请使用微信扫描二维码登录</div>
            <!-- <div class="wx-login-desc">关注公众号登录</div> -->
            <div class="login-serve wx-serve" style="margin-top: 56px">
              <span>登录即代表你同意</span>
              <span class="to-serve" @click="toPric(1)">用户协议</span>
              <span>和</span>
              <span class="to-serve" @click="toPric(2)">隐私政策</span>
            </div>
          </el-tab-pane>
          <!-- 手机登录 -->
          <el-tab-pane label="使用手机登录" name="second">
            <div style="padding: 0px 76px">
              <el-form
                ref="loginForm"
                style="margin-top: 15px"
                :model="loginForm"
              >
                <el-form-item prop="phone">
                  <el-input
                    v-model="loginForm.phone"
                    placeholder="请输入您的手机号"
                    oninput="value=value.replace(/[^\d]/g,'')"
                  />
                </el-form-item>
                <el-form-item
                  prop="code"
                  style="margin-top: 20px; margin-bottom: 15px"
                >
                  <el-input
                    v-model="loginForm.code"
                    style="width: 223px; margin-right: 20px"
                    placeholder="请输入验证码"
                    oninput="value=value.replace(/[^\d]/g,'')"
                  />
                  <div
                    class="get-code"
                    :class="disabled ? 'dis' : ''"
                    @click="getCode"
                  >
                    {{ btnText }}
                  </div>
                </el-form-item>
              </el-form>
              <div class="login-tips">
                <span v-show="showLoginTips">{{ loginTips }}</span>
              </div>
              <div class="login-btn" @click="handlerLogin">登录</div>
              <div class="login-serve wx-serve" style="margin-top: 56px">
                <span>登录即代表你同意</span>
                <span class="to-serve" @click="toPric(1)">用户协议</span>
                <span>和</span>
                <span class="to-serve" @click="toPric(2)">隐私政策</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
    </div>
  </div>
</template>
<script type='text/javascript' src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import { menuList, feelTools } from "../utils/menu";
import { passportPost, hxsGet } from "../apis/index";
import { commonParams } from "@/utils/publicParam";
import AES from "@/utils/ase.js";
export default {
  data() {
    return {
      menuList,
      feelTools,
      vipList: [
        {
          name: "尊享所有功能",
          pic: require("../assets/img/home/v1.png")
        },
        {
          name: "文件大小最高100M",
          pic: require("../assets/img/home/v2.png")
        },
        {
          name: "尊贵VIP身份",
          pic: require("../assets/img/home/v3.png")
        },
        {
          name: "转换记录时长增加",
          pic: require("../assets/img/home/v4.png")
        }
      ],
      loginDialog: false,
      activeName: "first",
      loginForm: {
        phone: "",
        code: ""
      },
      disabled: false,
      btnText: "获取验证码",
      showLoginTips: false,
      loginTips: "",
      time: 60,
      showUser: false,
      wxLoading: true,
      wxLoginImg: "",
      isLogin: JSON.parse(localStorage.getItem("isLogin")) || false,
      isVip: JSON.parse(localStorage.getItem("isVip")),
      head: localStorage.getItem("head"),
      userName: localStorage.getItem("userName"),
      vipDate: localStorage.getItem("vipDate"),
      timeZone: localStorage.getItem("timeZone"),
      loginTimer: null,
      loginId: "",
      smsTimer: null
    };
  },
  created() {
    if (localStorage.getItem("isLogin")) {
      this.getUserVip();
    }
    if (this.$route.query.qid) {
      localStorage.setItem("qid", this.$route.query.qid);
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (this.$route.query.qid) {
          localStorage.setItem("qid", this.$route.query.qid);
        }
        if (val.query.code && val.query.code.length > 0) {
          hxsGet(
            "doconline/open/user/wechat/login.html/?code=" +
              val.query.code +
              "&state=" +
              val.query.state +
              "&qid=" +
              localStorage.getItem("qid")
          )
            .then((res) => {
              window.localStorage.setItem(
                "token",
                JSON.parse(res.data.token).token
              );
              window.localStorage.setItem("isLogin", true);
              this.isLogin = true;
              this.loginDialog = false;
              this.getUserVip();
              window.location.href = window.location.href.substring(
                0,
                window.location.href.indexOf("?")
              );
            })
            .catch((err) => {
              this.$message.error(err);
              setTimeout(() => {
                window.location.href = window.location.href.substring(
                  0,
                  window.location.href.indexOf("?")
                );
              }, 2000);
            });
        }
      },
      deep: true // 深度观察监听
    },
    isLogin: function (val, oldVal) {
      this.$parent.isLogin = val;
    }
  },
  methods: {
    // 跳转隐私政策用户协议
    toPric(i) {
      if (i === 1) {
        this.$router.push("/licence");
      } else {
        this.$router.push("/privacy");
      }
    },
    formatDate(value) {
      if (typeof value === "undefined") {
        return "";
      } else {
        const date = new Date(parseInt(value));
        const y = date.getFullYear();
        let MM = date.getMonth() + 1;

        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();

        d = d < 10 ? "0" + d : d;

        return y + "/" + MM + "/" + d;
      }
    },
    // 获取微信回调地址code, state参数
    getQueryString(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      const r = window.location.search.substr(1).match(reg);

      if (r != null) return unescape(r[2]);
      return null;
    },
    // 回到首页
    toHome() {
      this.$router.push("/");
    },
    // 跳转对应转换页
    toConvert(i) {
      window.open(i);
    },
    // 跳转商品介绍页
    openVip() {
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "蓝山在线文档转换",
          "开通会员",
          "公共头部开通会员按钮点击次数"
        ]);
      }
      this.$router.push("/product");
    },
    // 打开登录dialog 加载微信公众号二维码
    OpenDialog() {
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "蓝山在线文档转换",
          "登录入口",
          "登录按钮点击次数"
        ]);
      }
      this.wxLoading = true;
      this.loginDialog = true;
      const params = {
        ...commonParams,
        loginType: 9,
        sceneId: 1
      };

      passportPost("/user/getQR", params, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8"
        }
      })
        .then((res) => {
          this.wxLoginImg = res.data.qrUrl;
          this.wxLoading = false;
          this.loginId = res.data.loginId;
          this.loginTimer = setInterval(() => {
            this.getWxLoginStatus(res.data.loginId);
          }, 2000);
          this.wxLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.wxLoading = false;
        });
    },
    // wx网站应用登录
    OpenWxWebLogin() {
      this.wxLoading = true;
      this.loginDialog = true;
      setTimeout(() => {
        this.obj = new WxLogin({
          self_redirect: false,
          id: "wxloginD",
          appid: process.env.VUE_APP_VXAPPID,
          scope: "snsapi_login",
          redirect_uri: encodeURIComponent(window.location.href),
          state: "",
          style: "",
          href: "https://" + document.location.host + "/wx.css"
        });
      }, 1000);
    },
    // 关闭登录弹窗
    handleClose() {
      this.loginDialog = false;
      this.loginTips = "";
      this.showLoginTips = false;
      this.loginForm = { phone: "", code: "" };
      clearInterval(this.loginTimer);
    },
    // 获取验证码
    getCode() {
      if (this.disabled) {
        // 如果禁用直接返回，不给点击
        return false;
      } else {
        // 如果没禁用，开启定时器倒计时
        if (!/^1[3456789]\d{9}$/.test(this.loginForm.phone)) {
          this.showLoginTips = true;
          this.loginTips = "手机号输入有误";
          return false;
        } else {
          this.showLoginTips = false;
          this.disabled = true;
          this.time = 60;
          this.timer();
          hxsGet(
            "doconline/open/login/sendmsg.html?mobile=" + this.loginForm.phone
          )
            .then((res) => {
              this.showLoginTips = false;
              this.time = 60;
              this.disabled = true;
              this.timer();
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      }
    },
    // 登录
    handlerLogin() {
      if (!/^1[3456789]\d{9}$/.test(this.loginForm.phone)) {
        this.showLoginTips = true;
        this.loginTips = "手机号输入有误";
        return false;
      } else {
        if (this.loginForm.code !== "") {
          hxsGet(
            "doconline/open/user/mobile/login.html?mobile=" +
              this.loginForm.phone +
              "&code=" +
              this.loginForm.code +
              "&qid=" +
              localStorage.getItem("qid")
          )
            .then((res) => {
              if (res.success) {
                window.localStorage.setItem(
                  "loginStatus",
                  res.data.loginStatus
                );
                window.localStorage.setItem(
                  "token",
                  JSON.parse(res.data.token).token
                );
                window.localStorage.setItem("isLogin", true);
                this.isLogin = true;
                this.$emit("handleLogin", true);
                this.handleClose();
                this.getUserVip();
              } else {
                this.showLoginTips = true;
                this.loginTips = res.msg;
              }
            })
            .catch((err) => {
              this.showLoginTips = true;
              this.loginTips = err.msg;
            });
        } else {
          this.showLoginTips = true;
          this.loginTips = "请输入验证码";
        }
      }
    },
    // 验证码倒计时
    timer() {
      clearTimeout(this.smsTimer);
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btnText = this.time + "s";
        this.smsTimer = setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btnText = "发送验证码";
        this.disabled = false;
        this.showLoginTips = false;
        this.showBindTips = false;
      }
    },
    // 获取日期
    getTimeCode() {
      const date = new Date();
      const y = ("" + date.getFullYear()).substring(2);
      const ms = "0" + (date.getMonth() + 1);
      const m = ms.substring(ms.length - 2);
      const d = date.getDate();
      const dateStr = y + m + d;
      return dateStr;
    },
    // 打开&关闭用户信息弹窗
    // showUserDialog() {
    //   this.showUser = !this.showUser;
    // },
    // 退出登录
    loginOut() {
      window.localStorage.clear();
      this.handleClose();
      this.isLogin = false;
      this.showUser = false;
      this.isVip = false;
      this.$emit("handleLogin", false);
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "蓝山在线文档转换",
          "退出",
          "用户点击退出登录按钮退出登录"
        ]);
      }
      if (this.$route.path === "/history") {
        this.$router.push("/");
      }
    },
    // 获取会员信息
    getUserVip() {
      hxsGet(
        "doconline/open/user/getuinfo.html/?token=" +
          window.localStorage.getItem("token")
      ).then((res) => {
        if (res.code && res.code === "301001") {
          this.loginOut();
        }
        var data = JSON.parse(AES.decrypt(res.data.data));
        window.localStorage.setItem("isLogin", true);
        this.isVip = data.isvip;
        this.head = data.user.uicon
          ? data.user.uicon
          : "../assets/img/initUserAvatar.png";
        this.userName = data.user.nickName;
        if (this.isVip) {
          this.timeZone =
            this.formatDate(data.vipinfo.startDate) +
            "-" +
            this.formatDate(data.vipinfo.endDate);
        } else {
          this.timeZone = "普通用户";
        }
        localStorage.setItem("isVip", this.isVip);
        localStorage.setItem("head", this.head);
        localStorage.setItem(
          "vipDate",
          data.vipinfo ? data.vipinfo.endDate : 0
        );
        localStorage.setItem("userName", this.userName);
        localStorage.setItem("timeZone", this.timeZone);
      });
    },
    // 跳转转换记录
    toHistory() {
      this.$router.push("/history");
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "蓝山在线文档转换",
          "转换记录相关",
          "头部转换记录按钮点击次数"
        ]);
      }
    },
    enter() {
      this.showUser = !this.showUser;
    }
  }
};
</script>

<style lang="scss">
.head-fixed {
  height: 68px;

  .header {
    width: 100%;
    height: 68px;
    border-bottom: 1px solid #e7eaf1;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: #fff;

    .header-content {
      width: 1200px;
      min-width: 1200px;
      height: 68px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #212121;

        > :not(.header-icon) {
          display: none;
        }

        .header-icon {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          margin-right: 40px;

          img {
            margin-right: 10px;
          }
        }

        .header-home {
          cursor: pointer;
          color: #212121;
        }

        .header-home:hover {
          color: #0078f8;
        }

        .header-ml {
          margin-left: 35px;
        }
      }

      .header-btn {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #212121;

        .header-history {
          margin-right: 20px;
          cursor: pointer;
        }

        .header-history:hover {
          color: #0078f8;
        }

        .header-vip {
          width: 120px;
          height: 36px;
          background: linear-gradient(45deg, #fae8cb 0%, #e5bf8d 100%);
          border-radius: 4px;
          margin-left: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .header-vip-icon {
            width: 20px;
            height: 16px;
            margin-right: 8px;
          }

          .header-vip-text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #775026;
          }
        }

        .header-vip:hover {
          background: linear-gradient(
            45deg,
            #f6deb7 0%,
            #dab178 100%
          ) !important;
        }

        .header-register {
          width: 80px;
          height: 36px;
          background: #0078f8;
          border-radius: 4px;
          margin-left: 10px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #fff;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
        }

        .header-register:hover {
          background: #268cf9;
        }

        .header-head-wrap {
          margin-left: 20px;
          position: relative;
          cursor: pointer;

          .header-userinfo-wrap {
            position: absolute;
            // top: 40px;
            left: -170px;
            z-index: 888;
            padding-top: 50px;
            @media screen and (max-width: 1450px) {
              margin-left: -110px;
            }
            .header-user-info {
              width: 320px;
              height: 410px;
              background: #ffffff;
              box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.16);
              border-radius: 7px 7px 7px 7px;

              .header-user-top {
                height: 70px;
                background: url("../assets/img/home/v-t.png") no-repeat;
                background-size: 100% 100%;
                padding: 13px 0 13px 21px;
                display: flex;
                align-items: center;
                box-sizing: border-box;

                .header-top-header {
                  width: 44px;
                  height: 44px;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-right: 22px;
                }

                .header-top-name {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #222222;
                }
              }

              .header-user-b {
                .vip-times {
                  margin: 17px 0 17px 22px;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #222222;
                }

                .vip-btn {
                  width: 280px;
                  height: 40px;
                  background: linear-gradient(90deg, #fae8cb, #e5bf8d);
                  border-radius: 20px;
                  margin: 0 auto;
                  text-align: center;
                  line-height: 40px;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #83593f;
                  cursor: pointer;
                }

                .vip-btn:hover {
                  background: linear-gradient(
                    45deg,
                    #f6deb7 0%,
                    #dab178 100%
                  ) !important;
                }

                .vip-item-wrap {
                  padding: 13px 23px 20px 44px;
                  box-sizing: border-box;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  flex-wrap: wrap;

                  .vip-item {
                    .vip-item-img {
                      width: 48px;
                      height: 48px;
                      margin: 0 auto;
                    }

                    .vip-item-name {
                      font-size: 13px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #666666;
                      margin-top: 7px;
                    }
                  }

                  .vip-item:nth-child(1),
                  .vip-item:nth-child(2) {
                    margin-bottom: 29px;
                  }
                }

                .vip-login-out {
                  padding-right: 24px;
                  height: 38px;
                  border-top: 1px solid #e8e8e8;
                  text-align: right;
                  line-height: 38px;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #999999;
                  cursor: pointer;
                }

                .vip-login-out:hover {
                  background: #f8f8f8;
                  color: #0078f8;
                }
              }
            }
          }

          .header-head {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            // overflow: hidden;
            position: relative;

            .vip-border {
              width: 50px;
              height: 50px;
              position: absolute;
              top: -11px;
              left: 0;
            }

            .user-header {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

.dis {
  background-color: #cccccc !important;
  color: #fff !important;
  cursor: no-drop !important;
}

.line-login {
  height: 18px;
  width: 1px;
  background-color: #0078f8;
  position: absolute;
  top: 62px;
  left: 50%;
}

.el-dropdown {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #212121;
  cursor: pointer;

  :hover {
    color: #0078f8;
  }
}

.get-code {
  width: 124px;
  height: 45px;
  background: #0078f8;
  color: #fff;
  text-align: center;
  line-height: 45px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  font-family: Microsoft YaHei UI;
  display: inline-block;
  cursor: pointer;
}

.get-code:hover {
  background-color: #268cf9;
}

.login-tips {
  color: #ef4a45;
  font-size: 12px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  height: 17px;
}

.login-btn {
  width: 368px;
  height: 45px;
  margin: 50px auto 0 auto;
  background: #0078f8;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  font-weight: 400;
  font-family: Microsoft YaHei UI;
  cursor: pointer;
}

.login-btn:hover {
  background: #268cf9;
}

.login-serve {
  font-size: 14px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 18px;
  color: #000;
  margin-top: 35px;
  text-align: center;
  margin-bottom: 20px;

  .to-serve {
    color: #0078f8;
    cursor: pointer;
  }
}

.wx-code {
  // width: 300px;
  // height: 400px;
  // background-color: red;
  width: 200px;
  height: 200px;
  margin: 25px auto 0 auto;
  position: relative;

  .wx-login-mask {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;

    .wx-login-loading-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .wx-login-loading {
        font-size: 24px;
        color: #0078f8;
      }
    }
  }
}

.wx-login-desc {
  text-align: center;
  margin-top: 5px;
}

.wx-serve {
  margin-top: 12px;
}

.el-tabs__nav-wrap::after {
  background: transparent !important;
}

.el-tabs__item {
  font-size: 18px !important;
  font-weight: 400 !important;
  font-family: Microsoft YaHei UI;
}

.el-tabs__item:hover {
  color: #0078f8 !important;
}

.el-tabs__item.is-active {
  color: #0078f8 !important;
}

.el-tabs__active-bar {
  background-color: #0078f8 !important;
}

.el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

.el-dialog {
  height: 470px;
}

.el-dialog__body,
.el-dialog__header {
  // padding: 30px 76px !important;
  padding: 0 !important;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ecf5ff;
  color: #0078f8;
}
</style>
