import md5 from 'js-md5';

function getTimeCode() {
  const date = new Date();
  const y = ("" + date.getFullYear()).substring(2);
  const ms = "0" + (date.getMonth() + 1);
  const m = ms.substring(ms.length - 2);
  const d = date.getDate();
  const dateStr = y + m + d;
  return dateStr;
}
const channelDate = "000000" + getTimeCode();
const agent = navigator.userAgent;

export const commonParams = {
  appverint: "",
  devicebrand: "",
  province: "",
  city: "",
  country: "",
  ciappverty: "",
  apptypeid: "500004",
  network: "other",
  istourist: "",
  aaid: "",
  obatchid: "",
  pixel: "",
  appqid: channelDate,
  appcqid: "000000",
  oaid: "",
  os: "WEB_WIN",
  deviceid: md5(agent),
  muid: "",
  imei: md5(agent),
  isyueyu: "",
  osversion: "",
  device: "",
  srcplat: "",
  srcqid: "",
  accid: "",
  projectCode: "zhuanhuan",
  uuid: "",
  version: "CH"
}
