/**
 * 对axios二次封装
 */
import axios from "axios";
import onError from "./error";

class Request {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.downloadUrl = ['/file/transform', '/file/all_down_load'] // 下载文件
  }
  // 获取axios配置
  getConfig() {
    const config = {
      baseURL: this.baseUrl,
      timeout: 6000
    };
    return config;
  }
  // 设定拦截器
  interceptors(instance) {
    // 添加请求拦截器
    instance.interceptors.request.use(
      (config) => {
        return config;
      },
      (err) => {
        onError(err);
        return Promise.reject(err);
      }
    );

    // 添加响应拦截器
    instance.interceptors.response.use(
      (res) => {
        // console.log('instance.interceptors.response', res)
        const url = res.config.url.split('?')[0]

        if (this.downloadUrl.indexOf(url) >= 0) {
          return res.data;
        }
        if (!res.code) {
          return Promise.resolve(res.data);
        } else if (typeof (res.data) === 'string') {
          return Promise.resolve(res.data);
        } else {
          onError(res);
          return Promise.reject(res);
        }
      },
      (err) => {
        onError(err);
        return Promise.reject(err);
      }
    );
  }
  // 创建实例
  request(options) {
    const instance = axios.create();
    const newOptions = Object.assign(this.getConfig(), options);

    this.interceptors(instance);
    return instance(newOptions);
  }
  get(url, config) {
    const options = Object.assign({ method: "get", url }, config);
    return this.request(options);
  }
  post(url, data, config) {
    const options = Object.assign({ method: "post", url, data }, config);
    return this.request(options);
  }
}

export default Request;
