import CryptoJS from "crypto-js";

export default {
  // 加密
  encrypt(word) {
    const key = CryptoJS.enc.Utf8.parse("1NjrJCvcqTLSw9$h");
    const iv = CryptoJS.enc.Utf8.parse("&WsK6lz9CFJs$wnI");
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  },
  // 解密
  decrypt(word) {
    const key = CryptoJS.enc.Utf8.parse("1NjrJCvcqTLSw9$h");
    const iv = CryptoJS.enc.Utf8.parse("&WsK6lz9CFJs$wnI");
    const decrypt = CryptoJS.AES.decrypt(word, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
  }
};
